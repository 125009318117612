import React, { useEffect, useState } from 'react';
import {
  Container, Grid, TextField, Button, Paper, Table, TableBody,
  TableCell, TableHead, TableRow, Typography, IconButton, Box
} from '@mui/material';
import { Print as PrintIcon, Save as SaveIcon } from '@mui/icons-material';
import { Autocomplete } from '@mui/material';
import axios from 'axios';

import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Footer from '../components/Footer';

const useStyles = {
  content: {
    flexGrow: 1,
    padding: '24px',
    marginLeft: 240,
    marginTop: '80px',
    marginBottom: '15px',
  },
};

const Relatorios = () => {
  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [motorista, setMotorista] = useState(null);
  const [motoristas, setMotoristas] = useState([]);
  const [relatorios, setRelatorios] = useState([]);
  const [filteredRelatorios, setFilteredRelatorios] = useState([]);

  useEffect(() => {
    fetchMotoristas();
  }, []);

  const fetchMotoristas = async () => {
    try {
      const response = await axios.get('https://coopergraos.com.br/api_nova/motoristas.php');
      setMotoristas(response.data);
    } catch (error) {
      console.error('Erro ao buscar motoristas:', error);
    }
  };

  const fetchRelatorios = async () => {
    try {
      const response = await axios.get('https://coopergraos.com.br/api_nova/relatorios.php', {
        params: {
          dataInicial,
          dataFinal,
          motorista: motorista?.nome || '',
        },
      });
      setRelatorios(response.data);
      setFilteredRelatorios(response.data);
    } catch (error) {
      console.error('Erro ao buscar relatórios:', error);
    }
  };

  

  const handlePrint = () => {
    window.print();
  };

  return (
    <>
      <Header pageTitle="Relatórios" />
      <Sidebar />

      <main style={useStyles.content}>
        <Container>
          <Typography variant="h4" sx={{ my: 4, textAlign: 'center' }}>
            Relatórios de Embarques e Produtos
          </Typography>

          {/* Filtros */}
          <Paper sx={{ p: 4, mb: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Data Inicial"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={dataInicial}
                  onChange={(e) => setDataInicial(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  label="Data Final"
                  type="date"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={dataFinal}
                  onChange={(e) => setDataFinal(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  options={motoristas}
                  getOptionLabel={(option) => option.nome}
                  value={motorista}
                  onChange={(e, newValue) => setMotorista(newValue)}
                  renderInput={(params) => (
                    <TextField {...params} label="Motorista" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{ height: '100%' }}
                  onClick={fetchRelatorios}
                >
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
          </Paper>

          {/* Resultados */}
          {filteredRelatorios.length > 0 && (
            <>
              <Paper sx={{ p: 4, mb: 4 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Data Embarque</TableCell>
                      <TableCell>Data Entrada</TableCell>
                      <TableCell>Motorista</TableCell>
                      <TableCell>Produto</TableCell>
                      <TableCell>Quantidade</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRelatorios.map((relatorio, index) => (
                      <TableRow key={index}>
                        <TableCell>{relatorio.data_embarque}</TableCell>
                        <TableCell>{relatorio.data_entrada}</TableCell>
                        <TableCell>{relatorio.motorista}</TableCell>
                        <TableCell>{relatorio.produto}</TableCell>
                        <TableCell>{relatorio.quantidade}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>

              {/* Ações */}
              <Box sx={{ textAlign: 'right', mb: 4 }}>
                <IconButton color="primary" onClick={handlePrint}>
                  <PrintIcon />
                </IconButton>
              
              </Box>
            </>
          )}
        </Container>
      </main>

      <Footer />
    </>
  );
}

export default Relatorios;
