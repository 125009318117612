import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, ListItemIcon, Drawer } from '@mui/material';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EditIcon from '@mui/icons-material/Edit';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Link, useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = {
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#ffffff',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85px',
    padding: 5,
    margin: 20,
  },
  logoImage: {
    width: '80%',
    maxWidth: '120px',
    height: 'auto',
  },
  listItemIcon: {
    color: '#34a853',
  },
  listItemText: {
    color: '#34a853',
  },
};

const Sidebar = () => {
  const navigate = useNavigate();
  const [nivelAcesso, setNivelAcesso] = useState(null);

  useEffect(() => {
    // Recupera o nível de acesso do localStorage e o define no estado
    const acesso = parseInt(localStorage.getItem('nivelAcesso'), 10);
    setNivelAcesso(acesso);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('nivelAcesso');
    navigate('/login');
  };

  return (
    <Drawer
      variant="permanent"
      sx={useStyles.drawer}
      PaperProps={{
        sx: useStyles.drawerPaper,
      }}
    >
      <div style={useStyles.logoContainer}>
        <img 
          src="https://athix.com.br/img/bg_cooperfibra.png" 
          alt="Logo" 
          style={useStyles.logoImage} 
        />
      </div>

      <List>
        <ListItem button component={Link} to="/CadastroMotorista" sx={{ cursor: 'pointer' }}>
          <ListItemIcon sx={useStyles.listItemIcon}>
            <DirectionsCarIcon />
          </ListItemIcon>
          <ListItemText primary="Cadastro de Motorista" sx={useStyles.listItemText} />
        </ListItem>

        <ListItem button component={Link} to="/CadastroProdutor" sx={{ cursor: 'pointer' }}>
          <ListItemIcon sx={useStyles.listItemIcon}>
            <DirectionsCarIcon />
          </ListItemIcon>
          <ListItemText primary="Cadastro Produtor" sx={useStyles.listItemText} />
        </ListItem>

        <ListItem button component={Link} to="/dashboard" sx={{ cursor: 'pointer' }}>
          <ListItemIcon sx={useStyles.listItemIcon}>
            <DirectionsCarIcon />
          </ListItemIcon>
          <ListItemText primary="Painel de Status" sx={useStyles.listItemText} />
        </ListItem>

        <ListItem button component={Link} to="/ControleEmbarque" sx={{ cursor: 'pointer' }}>
          <ListItemIcon sx={useStyles.listItemIcon}>
            <DirectionsCarIcon />
          </ListItemIcon>
          <ListItemText primary="Controle de Embarque" sx={useStyles.listItemText} />
        </ListItem>

        <ListItem button component={Link} to="/ControleEntrada" sx={{ cursor: 'pointer' }}>
          <ListItemIcon sx={useStyles.listItemIcon}>
            <DirectionsCarIcon />
          </ListItemIcon>
          <ListItemText primary="Controle de Entrada de Produto" sx={useStyles.listItemText} />
        </ListItem>

        {/* Renderiza Usuários apenas se nivelAcesso não for 2 */}
        {nivelAcesso !== 2 && (
          <ListItem button component={Link} to="/usuarios" sx={{ cursor: 'pointer' }}>
            <ListItemIcon sx={useStyles.listItemIcon}>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Usuários" sx={useStyles.listItemText} />
          </ListItem>
        )}

       
<ListItem button component={Link} to="/relatorios" sx={{ cursor: 'pointer' }}>
<ListItemIcon sx={useStyles.listItemIcon}>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Relatórios" sx={useStyles.listItemText} />
          </ListItem>
       
        

        <ListItem button onClick={handleLogout} sx={{ cursor: 'pointer' }}>
          <ListItemIcon sx={useStyles.listItemIcon}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" sx={useStyles.listItemText} />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
